import React, { useEffect, useState } from 'react';
import { SubscriptionCard } from './SubscriptionCard';
import styles from './PayementPage.module.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import payImg from '../../assets/img/payement-types.png';
import { getUser } from '../../api/user.api';
import { cancelSub } from '../../api/user.api';
import { User_Interface } from '../../interfaces/User.interface';
import { CancelSubscriptionModal } from '../../components/Modal/CancelSubscriptionModal/CancelSubscriptionModal';
import { self } from '../../api/user.api';
import { setLoggedUserActionCreator } from '../../reducers/LoggedUser';
import { Col, Container, Row } from 'react-bootstrap';

export default function PayementPage(): JSX.Element {
  const [expDate, setExpDate] = useState<Date>(new Date());
  const stripePromise = loadStripe(
    'sk_test_51MKNgVBVxRVLzVAl4sgmntPExunKzyIpOeWCUbzFipr6SlNTkJVFtRvjNNZw8gGqvI500icAObRhtap6UmmHY68B00Vw7qzqAM',
  );
  const options = {
    clientSecret: '{{CLIENT_SECRET}}',
  };
  const loggedUser = useSelector((state: RootState) => state.loggedUser);
  const [plan, setPlan] = useState<string>('');
  const [user, setUser] = useState<User_Interface>();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const dispatch = useDispatch();

  const urlBasic = `${process.env.REACT_APP_LINK_BASIC}`;
  const urlStandard = `${process.env.REACT_APP_LINK_STANDARD}`;
  const urlPremium = `${process.env.REACT_APP_LINK_PREMIUM}`;

  async function cancelSubscription(): Promise<void> {
    const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
    if (loggedUser) {
      cancelSub(parseInt(loggedUser?.id.toString()))
        .then(() => {
          self().then((loggedUser) => dispatch(setLoggedUserActionCreator(loggedUser)));
        })
        .finally(() => {
          setPlan('Free Plan - Unlimited');
          setExpDate(new Date());
        });
      setShowConfirm(false);
    }
  }
  useEffect(() => {
    if (loggedUser) {
      getUser(parseInt(loggedUser?.id.toString())).then((result) => {
        setUser(result);
        if (result?.plan_type) {
          setPlan(result.plan_type);
          setExpDate(new Date(result.sub_expiration));
        } else {
          setPlan('Free Plan - Unlimited');
        }
      });
    }
  }, []);
  return (
    <Elements stripe={stripePromise} options={options}>
      <div>
        <div>
          <h1 className={styles.title}>Pricing</h1>
          <p className={styles.subtitle}>
            Choose a paid plan that works for <br />
            you , or keep a free plan
          </p>
          <img src={payImg} className={styles.logos} />
        </div>
        <CancelSubscriptionModal
          show={showConfirm}
          handleClose={() => setShowConfirm(false)}
          handleConfirm={() => cancelSubscription()}
        />
        {!!loggedUser ? (
          <>
            <Container fluid={true}>
              <Row className={styles.row}>
                <Col xs={12} sm={6} md={6} lg={4} className={styles.myCol}>
                  <SubscriptionCard
                    userPlan={plan}
                    subExpiration={expDate.toDateString()}
                    type="Basic"
                    cancelSub={() => setShowConfirm(true)}
                    link={urlBasic}
                  />
                </Col>
                <Col xs={12} sm={6} md={6} lg={4} className={styles.myCol}>
                  <SubscriptionCard
                    userPlan={plan}
                    subExpiration={expDate.toDateString()}
                    type="Standard"
                    cancelSub={() => setShowConfirm(true)}
                    link={urlStandard}
                  />
                </Col>
                <Col xs={12} sm={6} md={6} lg={4} className={styles.myCol}>
                  <SubscriptionCard
                    userPlan={plan}
                    subExpiration={expDate.toDateString()}
                    type="Premium"
                    cancelSub={() => setShowConfirm(true)}
                    link={urlPremium}
                  />
                </Col>
              </Row>
            </Container>
          </>
        ) : (
          <div className={styles.flexboxContainer}>
            <Container fluid={true}>
              <Row className={styles.row}>
                <Col xs={12} sm={6} md={6} lg={4} className={styles.myCol}>
                  <SubscriptionCard type="Basic" link="/login" />
                </Col>
                <Col xs={12} sm={6} md={6} lg={4} className={styles.myCol}>
                  <SubscriptionCard recommended={true} type="Standard" link="/login" />
                </Col>
                <Col xs={12} sm={6} md={6} lg={4} className={styles.myCol}>
                  <SubscriptionCard type="Premium" link="/login" />
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </div>
    </Elements>
  );
}
